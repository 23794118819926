
import { defineComponent, ref, onMounted } from 'vue'
import { game } from './Balljump'

export default defineComponent({
  name: 'balljump',
  components: {},
  setup() {
    const canvasRef = ref<HTMLCanvasElement>()
    const divRef = ref<HTMLDivElement>()

    const isGameOver = ref(false)
    const score = ref('')

    onMounted(() => {
      game(canvasRef, divRef, isGameOver, score)
    })

    const restart = () => {
      isGameOver.value = false
      game(canvasRef, divRef, isGameOver, score)
    }

    return { canvasRef, divRef, isGameOver, restart, score }
  }
})
